<template>
  <div class="reg_wrap">
    <div class="w_max_min" :class="choseRole?'reg_cont':'reg_next_cont'">
      <div class="top_logo">
        <router-link to="/"><img src="../../assets/img/logo.png" class="top_logoImg" title="商易融" /></router-link>
      </div>
      <div class="reg_box">
        <div class="radio_user" v-show="choseRole">
          <div class="reg_title">请选择角色</div>
          <el-radio-group v-model="roleId">
            <el-radio-button v-for="(item,index) in userList" :key='index' :label="item.roleId">{{item.name}}</el-radio-button>
          </el-radio-group>
          <div class="btn_box_next">
            <el-button class="btn_next" @click="next()">下一步</el-button>
          </div>
        </div>
        <div v-show="!choseRole">
          <el-form :model="registerForm" :rules="regRules" ref="registerForm" class="reg_form">
            <div class="reg_title m_title_b">注册信息</div>
            <div class="err_bg" v-show="errFlag"><i class="iconfont icon-jinggao" /> &nbsp;<span>{{errMsg}}!</span></div>
            <el-form-item prop="cnCompany">
              <!-- <el-input v-model="registerForm.cnCompany" placeholder="请输入公司名称"  maxlength="20" @focus='errFlag=false'></el-input> -->
              <el-autocomplete class="inline-input" placeholder="请输入公司名称" v-model="registerForm.cnCompany" :fetch-suggestions="querySearch" :trigger-on-focus="true" @select="handleSelect">
              </el-autocomplete>
            </el-form-item>
            <el-form-item prop="cnName">
              <el-input v-model="registerForm.cnName" placeholder="请输入姓名" maxlength="20" @focus='errFlag=false'></el-input>
            </el-form-item>
            <el-form-item prop="position">
              <el-input v-model="registerForm.position" placeholder="请输入职位" maxlength="20" @focus='errFlag=false'></el-input>
            </el-form-item>
            <el-form-item prop="email">
              <el-input v-model="registerForm.email" placeholder="请输入邮箱" maxlength="50" @focus='errFlag=false'></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <!-- <el-input v-model="registerForm.falsePass" @input="getInptVal1(registerForm.falsePass)" placeholder="请输入密码" @focus='errFlag=false'>
                                <i slot="suffix"  class="iconfont icon-yanjing" @click="watchPass1()" autocomplete="auto"></i>
                            </el-input>
                            <el-input type="password" v-model="registerForm.password" style="display:none"/> -->
              <el-input v-model="registerForm.password" :type="type" placeholder="请输入密码" @focus='errFlag=false'>
                <i slot="suffix" class="iconfont icon-yanjing" autocomplete="auto" @click="inputType=!inputType"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="confirmPassword">
              <!-- <el-input v-model="registerForm.falseConfirmPass" @input="getInptVal2(registerForm.falseConfirmPass)" placeholder="确认密码" @focus='errFlag=false'>
                                <i slot="suffix"  class="iconfont icon-yanjing" @click="watchPass2()" autocomplete="auto"></i>
                            </el-input>
                            <el-input type="password" v-model="registerForm.confirmPassword" style="display:none"/> -->
              <el-input v-model="registerForm.confirmPassword" :type="type" placeholder="确认密码" @focus='errFlag=false'>
                <i slot="suffix" class="iconfont icon-yanjing" autocomplete="auto" @click="inputType=!inputType"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="phone">
              <el-input v-model="registerForm.phone" placeholder="请输入手机号码" maxlength="11"></el-input>
            </el-form-item>
            <el-form-item prop="code" class="send_code_group">
              <el-input v-model="registerForm.code" class="send_code" id="code" placeholder="请输入验证码" maxlength="20" @focus='errFlag=false'></el-input>
              <el-button class="btn btn_send_code" @click="sendCode" v-show="setTimeFlag">发送验证码</el-button>
              <div class="setTime" v-show="!setTimeFlag">{{setTime}}s</div>
            </el-form-item>
            <div class="check">
              <el-checkbox v-model="checkProvision" :change="changeCheck()">勾选表示已阅读并同意以下条款内容</el-checkbox>
              <div class="err_txt" v-show="errCheckFlg">{{errCheckMsg}}</div>
            </div>
            <el-form-item class="btn_box">
              <el-button class="btn_form_prev" @click="preStep()">上一步</el-button>
              <el-button class="btn_form_reg" @click="submitRegForm('registerForm')">注&nbsp;&nbsp;册</el-button>
            </el-form-item>
          </el-form>
          <div class="register">已有账号,<router-link :to="{ name: 'Dashboard', params: { choseRole: true }}">立即登录</router-link>
          </div>

          <!-- 确认角色 -->
          <!-- <el-dialog :title="dialogTitle" :visible.sync="sureRuleDialog" center class="dialog_width500" :close-on-click-modal="false">
                        <div class="revoke_confirm_box">
                            <template v-if="hasRole">
                                此账户已注册过{{roleId=='2'?'资 方':(roleId=='1'?'供应商':'核心企业')}}角色，不能重复注册。<br/>是否注册其他角色？
                            </template>
                            <template v-else-if="hasOtherRole">此账户已注册过供应商角色，是否继续注册其他角色？</template>
                            <template v-else>您已注册成功，快去登录了解详情吧！</template>
                        </div>
                        <span slot="footer" class="dialog-footer">
                            <template v-if="hasRole">
                                <el-button class="btn_form_prev" type="primary" @click="sureRuleDialog=false">取 消</el-button>
                                <el-button class="btn_form_reg dialog_button" type="primary" @click="sureRuleDialog=true" >选择角色</el-button>
                            </template>
                             <template v-else-if="hasOtherRole">
                                <el-button class="btn_form_prev" type="primary" @click="sureRuleDialog=false">上一步</el-button>
                                <el-button class="btn_form_reg dialog_button" type="primary" @click="sureRuleDialog=true">继续注册</el-button>
                            </template>
                            <template v-else>
                                <router-link class="btn_form_reg" :to="{ name: 'Dashboard', params: { popLoginForm: true }}">立即登录</router-link>
                            </template>
                        </span>
                    </el-dialog> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { editUser, doLogin, getCompanyNameList, getH5UrlGo, getCreateSignature } from "@/api/api.js";
import formValidation from "@/utils/rule.js";
import { Base64 } from 'js-base64'
export default {
  data() {
    //两次密码一致性校验
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.registerForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      checkProvision: true,//勾选条款
      userList: [{ roleId: '1', name: "供应商" }, { roleId: '3', name: "核心企业" }, { roleId: '2', name: "资 方" }],
      setTimeFlag: true,  //发送倒计时显示
      setTime: '',
      timer: null,
      choseRole: true,//选择角色
      roleId: '1',
      registerForm: {
        roleId: '',
        cnCompany: '',
        cnName: '',
        position: '',
        email: '',
        password: '',
        confirmPassword: '',
        phone: '',
        code: '',
        falsePass: '',//密码
        falseConfirmPass: '',//确认密码
      },
      //登录校验
      regRules: {
        cnCompany: [
          { required: true, message: '请输入公司名称', trigger: 'blur' },
        ],
        cnName: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        position: [
          { required: true, message: '请输入职位', trigger: 'blur' },
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: formValidation.validateEmail, trigger: "blur" }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            pattern: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,30}$/,
            message: '密码为数字,大小写字母,特殊符号 至少包含三种,长度为8-30位'
          }
        ],
        confirmPassword: [
          { required: true, validator: validatePass, trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号码' },
          { validator: formValidation.validateMobile, trigger: "blur" }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      },
      errMsg: '邮箱或手机号码已注册',
      errFlag: false,//是否显示邮箱或手机号码
      errCheckMsg: '请勾选同意条款内容',
      errCheckFlg: false,
      // sureRuleDialog:false,//弹框判断是否账户存在
      // dialogTitle:'确认角色',
      // hasRole:true,//已存在角色，是否选择其他角色
      // hasOtherRole:false,//已注册过，是否注册其他角色
      confirmRule: false,//注册成功
      pass: '',//密码
      inputType: false,//查看密码
      pass2: '',//密码
      inputType2: true,//查看密码
      dataname: [],
      sign: '',
      content: '',
      time: '',
      nonceL: '',
      sha: ''
    }


  },
  computed: {
    type() {
      return this.inputType ? "text" : "password";
    }
  },
  mounted() {
    // var code = ''
    // var codeLength = 32
    // var random = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9)
    // for (var i = 0; i < codeLength; i++) {
    //   var index = Math.floor(Math.random() * 9)
    //   code += random[index]
    // }
    // this.nonceL = code
    // this.time = new Date().getTime()
    // console.log(this.time, this.nonceL)
    // this.content = '4db78c94d72d363f' + this.nonceL + this.time.toString() + '3addc7a09e02c9225c0b24b08d11ecc1'
    // var aes = crypto.encrypt(this.content)
    // console.log(aes)
    // this.sha = sha1('4db78c94d72d363f' + this.nonceL + this.time.toString() + '3addc7a09e02c9225c0b24b08d11ecc1')
    // console.log(this.sha)
    // this.$nextTick(() => {
    //   this.digestMessage(this.content).then(digestHex => {
    //     // console.log(digestHex)
    //     this.sign = digestHex
    //     console.log(this.sign)
    //   })
    // })
  },
  methods: {
    //查看密码
    //密码输入变成 *
    // getInptVal1(val){
    //     console.log('eee',val)
    //     if(val){
    // this.registerForm.password+=val.substr(val.length-1,1)
    //this.registerForm.falsePass=val.replace(/./g,'*')
    //        this.pass=this.registerForm.falsePass
    //     }else{
    //         this.registerForm.password=''
    //         this.registerForm.falsePass=''
    //         this.pass=''
    //     }
    // },
    //密码输入变成 *
    // getInptVal2(val){
    //     if(val){
    //this.registerForm.confirmPassword+=val.substr(val.length-1,1)
    //this.registerForm.falseConfirmPass=val.replace(/./g,'*')
    //        this.pass2=this.registerForm.falseConfirmPass
    //     }
    //     else{
    //         this.registerForm.confirmPassword=''
    //         this.registerForm.falseConfirmPass=''
    //         this.pass2='' 
    //     }
    // },
    //查看密码
    // watchPass1(){
    //     if(this.inputType){
    //         this.registerForm.falsePass=this.registerForm.password
    //     }else{
    //         this.registerForm.falsePass=this.pass
    //     }
    //     this.inputType=!this.inputType
    // },
    // watchPass2(){    
    //     if(this.inputType2){
    //         this.registerForm.falseConfirmPass=this.registerForm.confirmPassword
    //     }else{
    //         this.registerForm.falseConfirmPass=this.pass2
    //     }
    //     this.inputType2=!this.inputType2
    // },
    // 发送验证码
    sendCode() {
      this.errFlag = false
      if (this.registerForm.phone == "") {
        return
      }
      this.checkaccount()

    },

    //账号校验 获取手机号
    checkaccount() {
      if (!this.timer) {
        document.querySelector('#code').focus();
        const time = 60
        this.setTime = time;
        this.setTimeFlag = false;
        this.timer = setInterval(() => {
          if (this.setTime > 1 && this.setTime <= time) {
            this.setTime--;
          } else {
            this.setTimeFlag = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000)

      }
    },
    // async digestMessage(message) {
    //   const msgUint8 = new TextEncoder().encode(message);
    //   console.log(crypto.subtle, msgUint8)              // encode as (utf-8) Uint8Array
    //   const hashBuffer = await crypto.subtle.digest('SHA-1', msgUint8);
    //   // hash the message
    //   const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
    //   const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
    //   return hashHex;
    // },

    //注册
    async register() {
      let res = await editUser(this.registerForm)
      if (res && res.code == 200) {
        this.login()
        // const api = process.env.NODE_ENV == "development" ? 'http://192.168.100.35:8082' : 'http://47.103.58.191:9091'//'http://180.169.235.162:9091'
        let data = {
          orderNo: '1',
          companyName: this.registerForm.cnCompany,
          cburl: '/#/secure/fillInInformation'
        }
        const res = await getCreateSignature(data)
        // var decode = JSON.parse(Base64.decode(getData.data))
        // let headers = {
        //   applId: "4db78c94d72d363f",
        //   signature: decode.signature,
        //   nonce: decode.nonce,
        //   timestamp: decode.timestamp
        // }
        // const res = await getH5UrlGo(data, headers)
        let url = JSON.parse(JSON.stringify(Base64.decode(res.data)))
        // console.log(res.data)
        // console.log(url)
        window.open(url, '_blank')
      }
      else {
        this.errFlag = true
        this.errMsg = res.msg
        return false;
      }
    },
    //注册完需要自动登录
    async login() {
      let res = await doLogin('username=' + this.registerForm.email + '&password=' + encodeURIComponent(this.registerForm.password) + "&roleIds=" + this.roleId)
      if (res && res.code == 200) {
        localStorage.clear()
        let obj = res.data
        // this.roleId=='2'?obj.manageRoleId='2':(this.roleId=='1'?obj.supplyRoleId='1':obj.enterRoleId='3')
        let userInfo = { userId: res.data.id, roleId: obj.roleId }
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        localStorage.setItem('cnCompany', obj.cnCompany);

        this.$router.push({ name: 'FillInInformation', params: { userId: res.data.id } });
      }
    },
    //是否勾选
    changeCheck() {
      this.checkProvision ? this.errCheckFlg = false : this.errCheckFlg = true
    },

    //提交注册表单
    submitRegForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid && this.checkProvision) {
          this.register()
        } else {
          this.errCheckFlg = true
          return false;
        }
      });
    },

    //选择角色
    next() {
      if (this.roleId) {
        this.choseRole = false
        // this.sureRuleDialog=true
        this.registerForm.roleId = this.roleId
      }
    },
    //上一步
    preStep() {
      this.choseRole = true
      this.$refs.registerForm.resetFields()
    },

    // 供应商名称模糊搜索
    handleSelect(item) {
      this.registerForm.cnCompany = item.company_name
    },
    //获取供应商名称
    async getCompanyNameList() {
      if (this.registerForm.cnCompany) {
        let data = await getCompanyNameList('comName=' + this.registerForm.cnCompany)
        this.dataname = data.data
        if (this.dataname) {
          this.dataname.forEach((item, index) => {
            this.dataname[index].value = item.company_name
          });
        }
      }
      else {
        this.dataname = []
      }
    },
    querySearch(queryString, cb) {
      this.getCompanyNameList().then(() => {
        var restaurants = this.dataname;
        // cb(restaurants)
        var results = []
        if (restaurants) {
          results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
          // results = queryString ? restaurants : [];
        }
        
        cb(results);
      })
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase())!==-1);
      };
    },
  }
}
</script>
<style scoped>
  .reg_wrap {
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    min-width: 1280px;
    z-index: 0;
    zoom: 1;
    background: url('../../assets/img/reg_bg.png') no-repeat;
    background-color: #f7fafc;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
  }
  .reg_cont {
    width: 100%;
    padding: 220px 0 120px;
  }
  .reg_next_cont {
    width: 100%;
    padding: 70px 0 120px;
  }
  .w_max_min {
    max-width: 1600px;
    min-width: 1280px;
    margin: auto;
  }
  .top_logo {
    position: absolute;
    top: 20px;
  }
  .top_logoImg {
    height: 20px;
  }
  .reg_box {
    width: 550px;
    background: #ffffff;
    border-radius: 10px;
    margin: auto;
    padding: 40px 50px;
  }
  .radio_user .el-radio-group {
    display: flex;
    justify-content: space-between;
  }

  .radio_user /deep/ .el-radio-button__inner,
  .radio_user .el-radio-button {
    width: 128px;
    height: 50px;
    background: #f7fafc;
    border-radius: 25px;
    border: 1px solid #f7fafc;
  }
  .radio_user /deep/ .el-radio-button__inner {
    font-size: 18px;
    color: #54657f;
    line-height: 1.4;
  }
  .radio_user /deep/ .el-radio-button.is-active .el-radio-button__inner {
    color: #fff;
    background: #339c9b;
  }
  .radio_user
    /deep/
    .el-radio-button__orig-radio:checked
    + .el-radio-button__inner {
    position: relative;
    box-shadow: none;
  }
  .reg_form {
    position: relative;
  }
  .btn_box,
  .reg_form /deep/ .el-form-item:not(:last-child) {
    margin-bottom: 20px;
  }
  .btn_box >>> .el-form-item__content {
    text-align: center;
  }
  .reg_form /deep/ .el-input__inner {
    width: 100%;
    height: 50px;
    background: #f7fafc;
    border-radius: 25px;
    border: 1px solid #d6dbe4;
    color: #54657f;
    font-size: 16px;
  }
  .reg_form /deep/ .el-input__inner::placeholder {
    color: #7c969b;
  }
  .reg_form /deep/ .icon-yanjing {
    font-size: 16px;
    color: #92a2bc;
    position: absolute;
    width: 60px;
    right: 0;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
  }
  .reg_form /deep/.el-input__icon.el-icon-view.el-input__clear {
    display: none;
  }
  .reg_form /deep/ .send_code .el-input__inner {
    width: 300px;
  }
  .reg_form /deep/ .el-form-item__error {
    margin-left: 30px;
  }
  .send_code_group /deep/ .el-form-item__content {
    display: flex;
    justify-content: space-between;
  }
  .btn_form_prev,
  .btn_next,
  .setTime,
  .btn,
  .btn_form_reg {
    width: 100%;
    height: 50px;
    border-radius: 32px;
    font-size: 18px;
    color: #ffffff;
    border: none;
  }
  .btn_next,
  .setTime,
  .btn,
  .btn_form_reg {
    background: #339c9b;
  }
  .btn_form_prev {
    background: #7c969b;
  }
  .btn_form_prev,
  .btn_form_reg,
  .btn_box_next {
    width: 210px;
  }
  .btn_box_next {
    margin: 60px auto 0;
  }
  .btn_next,
  .btn_form_reg {
    box-shadow: 0px 16px 32px 0px rgba(47, 160, 159, 0.23);
  }
  .setTime,
  .btn_send_code {
    width: 140px;
    flex: none;
  }
  .setTime {
    background: #7c969b;
    text-align: center;
    line-height: 50px;
  }
  .register {
    font-size: 16px;
    margin-top: 20px;
    text-align: center;
    color: #54657f;
  }
  .register a {
    color: #339c9b;
  }
  .check {
    margin-bottom: 40px;
    position: relative;
  }
  .check /deep/ .el-checkbox__inner {
    width: 20px;
    height: 20px;
    background: #f7fafc;
    border: 1px solid #e1e7ea;
    border-radius: 50%;
    color: #54657f;
  }
  .check /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
  .check /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #339c9b;
    border-color: #339c9b;
  }
  .check /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #54657f;
  }
  .check /deep/ .el-checkbox__inner::after {
    width: 6px;
    height: 12px;
    left: 5px;
    top: 1px;
  }
  .err_bg {
    position: absolute;
    width: 280px;
    height: 68px;
    line-height: 58px;
    font-size: 14px;
    color: #fb6476;
    text-align: center;
    background-image: url(../../assets/img/log_err_bg.png);
    background-size: 280px 66px;
    background-repeat: no-repeat;
    top: 18px;
    left: 20%;
    z-index: 88;
  }

  .err_bg .icon-jinggao {
    font-size: 16px;
    color: #fb6476;
  }
  .err_txt {
    position: absolute;
    font-size: 12px;
    color: #fb6476;
    margin-left: 30px;
  }
  .reg_title {
    font-size: 20px;
    color: #162747;
    line-height: 26px;
    text-align: center;
  }
  .radio_user >>> .el-radio-group {
    margin-top: 50px;
  }
  .m_title_b {
    margin-bottom: 30px;
  }
  .el-button + .el-button {
    margin-left: 30px;
  }

  .revoke_confirm_box {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 50px;
  }
  .dialog_width500 >>> .el-dialog__title {
    font-size: 20px;
    line-height: 26px;
  }
  .dialog_width500 >>> .el-dialog--center .el-dialog__body {
    overflow: auto;
  }
  .dialog_width500 {
    margin-top: 18vh !important;
  }
  .el-autocomplete {
    width: 100%;
  }
  @media screen and (max-width: 1664px) {
    .reg_title {
      font-size: 13px;
      line-height: 17px;
    }
    .radio_user >>> .el-radio-group {
      margin-top: 33px;
    }
    .m_title_b {
      margin-bottom: 20px;
    }
    .reg_next_cont {
      padding: 46px 79px;
    }
    .reg_cont {
      padding: 145px 79px;
    }
    .top_logoImg {
      height: 13px;
    }
    .reg_box {
      width: 363px;
      border-radius: 7px;
      padding: 26px 20px;
    }

    .radio_user >>> .el-radio-button__inner,
    .radio_user >>> .el-radio-button {
      width: 84px;
      height: 33px;
      border-radius: 17px;
    }
    .radio_user >>> .el-radio-button__inner {
      padding: 8px 13px;
    }
    .radio_user >>> .el-radio-button__inner {
      font-size: 12px;
      line-height: 1.2;
    }

    .reg_form >>> .el-input__inner {
      font-size: 12px;
      height: 33px;
    }
    .reg_form >>> .send_code .el-input__inner {
      width: 198px;
    }
    .btn_box,
    .reg_form >>> .el-form-item:not(:last-child) {
      margin-bottom: 15px;
    }
    .reg_form >>> .el-form-item__error {
      margin-left: 10px;
      transform-origin: left;
      width: 102%;
    }
    .reg_form >>> .icon-yanjing {
      font-size: 10px;
      width: 40px;
      height: 33px;
      line-height: 33px;
    }
    .setTime,
    .btn_send_code {
      width: 92px;
    }
    .setTime,
    .btn,
    .btn_form_reg {
      height: 33px;
      line-height: 33px;
      border-radius: 21px;
      font-size: 12px;
      padding: 0;
    }
    .btn_form_prev,
    .btn_form_reg,
    .btn_box_next {
      width: 139px;
    }
    .dialog_width500 >>> .btn_form_prev,
    .dialog_width500 >>> .btn_form_reg,
    .dialog_width500 >>> .btn_box_next {
      width: 125px;
    }
    .btn_form_prev,
    .btn_form_reg,
    .btn_next {
      height: 33px;
      padding: 0;
      font-size: 12px;
    }
    .btn_box_next {
      margin: 40px auto 0;
    }
    .check {
      margin-bottom: 26px;
    }
    .check >>> .el-checkbox__inner {
      width: 15px;
      height: 15px;
    }
    .check >>> .el-checkbox__inner::after {
      width: 3px;
      height: 10px;
      left: 5px;
      top: 0;
    }
    .check >>> .el-checkbox__label {
      font-size: 12px;
    }
    .register {
      font-size: 12px;
      margin-top: 13px;
    }
    .err_bg {
      width: 280px;
      height: 45px;
      line-height: 38px;
      font-size: 12px;
      background-size: 280px 44px;
      top: 12px;
      left: 7%;
    }

    .err_bg .icon-jinggao {
      font-size: 12px;
    }
    .el-button + .el-button {
      margin-left: 20px;
    }
    .dialog_width500 >>> .dialog_button.el-button {
      margin-left: 20px;
    }
    .revoke_confirm_box {
      font-size: 12px;
      line-height: 17px;
      margin-bottom: 33px;
    }
    .dialog_width500 >>> .el-dialog__title {
      font-size: 14px;
      line-height: 17px;
    }
  }
</style>